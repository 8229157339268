<template>
  <div style="width: 100%; height: 100%" class="fadeIn">
    <div class="header">
      <div style="font-size: 18px">
        {{ $route.query.projectName }} 稼动率 ：<i
          @click="dateVisible = true"
          class="el-icon-date"
          style="font-size: 20px; color: blue"
        ></i>
      </div>
      <div
        style="
          width: 60px;
          cursor: pointer;
          color: rgb(2, 185, 128);
          font-size: 14px;
          line-height: 28px;
        "
        @click="$router.go(-1)"
      >
        <i class="el-icon-arrow-left"></i>返回
      </div>
    </div>
    <div class="oeebody">
      <div class="echartsBox">
        <div id="barBox"></div>
        <div id="pieBox"></div>
      </div>
      <div class="echartsBox">
        <div id="frequencybar"></div>
        <div id="frequencypie"></div>
      </div>
    </div>
    <el-dialog
      title="查询条件"
      width="90%
    "
      style="text-align: left"
      :visible.sync="dateVisible"
    >
      <van-notice-bar
        v-if="dateVisible"
        text="周查询和月查询为本年所有周和所有月,无需上传日期！"
      />
      选择方式：
      <van-radio-group
        v-model="radio"
        direction="horizontal"
        @chang="getUseRate"
      >
        <van-radio :name="1" icon-size="14px">日查询</van-radio>
        <van-radio :name="2" icon-size="14px">班查询</van-radio>
        <van-radio :name="3" icon-size="14px">周查询</van-radio>
        <van-radio :name="4" icon-size="14px">月查询</van-radio>
      </van-radio-group>
      <el-row v-if="radio == 1 || radio == 2">
        <div style="margin: 10px 0">
          开始日期：
          <el-date-picker
            v-model="dateValue.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div style="margin: 10px 0">
          结束日期：
          <el-date-picker
            v-model="dateValue.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getUseRate">确 定</el-button>
        <el-button @click="dateVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
let that;
import * as echarts from "echarts";
export default {
  data() {
    return {
      shiftName: [],
      weekDate: [],
      radio: 1,
      dateVisible: false,
      barData: [],
      dateValue: { startDate: "", endDate: "" },
      setBar(data, Ydata, ref) {
        let option = {
          title: {
            text: "颜色时长明细",
            subtext: "Duration detail",
            textStyle: {
              fontSize: 12,
            },
          },
          tooltip: {
            position: function (point, params, dom, rect, size) {
              // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
              // 提示框位置
              var x = 0; // x坐标位置
              var y = 0; // y坐标位置

              // 当前鼠标位置
              var pointX = point[0];
              var pointY = point[1];

              // 外层div大小
              // var viewWidth = size.viewSize[0];
              // var viewHeight = size.viewSize[1];

              // 提示框大小
              var boxWidth = size.contentSize[0];
              var boxHeight = size.contentSize[1];

              // boxWidth > pointX 说明鼠标左边放不下提示框
              if (boxWidth > pointX) {
                x = 5;
              } else {
                // 左边放的下
                x = pointX - boxWidth;
              }

              // boxHeight > pointY 说明鼠标上边放不下提示框
              if (boxHeight > pointY) {
                y = 5;
              } else {
                // 上边放得下
                y = pointY - boxHeight;
              }

              return [x, y];
            },
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
            formatter: function (params) {
              if (params.length % 3 == 0) {
                let formatterStr = "";
                if (params.length > 3) {
                  formatterStr =
                    params[0].axisValue + "<br/>" + that.shiftName[0] + "<br/>";
                } else {
                  if (that.radio == 3) {
                    let index = params[0].dataIndex;
                    formatterStr =
                      params[0].axisValue +
                      "<br/>" +
                      '<span style="color:#409eff">' +
                      "开始日期:" +
                      "  " +
                      that.weekDate[index]["start"] +
                      "<br/>" +
                      "结束日期:" +
                      " " +
                      that.weekDate[index]["end"] +
                      "<br/>" +
                      "</span>";
                  } else {
                    formatterStr = params[0].axisValue + "<br/>";
                  }
                }
                let arr = [];
                let all = "";
                let allt = "";
                let allT = "";
                let percentage = [];
                if (params.length > 1) {
                  all = Number(
                    params[0].data * 3600 +
                      params[1].data * 3600 +
                      params[2].data * 3600
                  );
                }
                if (params.length > 3) {
                  allt = Number(
                    params[3].data * 3600 +
                      params[4].data * 3600 +
                      params[5].data * 3600
                  );
                }
                if (params.length > 6) {
                  allT = Number(
                    params[6].data * 3600 +
                      params[7].data * 3600 +
                      params[8].data * 3600
                  );
                }
                params.forEach((item, index) => {
                  let data = item.data * 3600;
                  let str = "";
                  if (data / 3600 >= 1) {
                    str = str + (data - (data % 3600)) / 3600 + "小时";
                  }
                  if ((data % 3600) / 60 >= 1) {
                    str = str + ((data % 3600) - (data % 60)) / 60 + "分";
                  }
                  str = str + Math.floor(data % 60) + "秒";
                  arr.push(str);
                  if (index < 3) {
                    if (all == 0) {
                      percentage.push(0);
                    } else {
                      percentage.push(((data / all) * 100).toFixed(2) * 1);
                    }
                  } else if (index > 2 && index < 6) {
                    if (allt == 0) {
                      percentage.push(0);
                    } else {
                      percentage.push(((data / allt) * 100).toFixed(2) * 1);
                    }
                  } else if (index > 6) {
                    if (allT == 0) {
                      percentage.push(0);
                    } else {
                      percentage.push(((data / allT) * 100).toFixed(2) * 1);
                    }
                  }
                  if (index == 3) {
                    formatterStr +=
                      that.shiftName[1] +
                      "<br/>" +
                      item.marker +
                      item.seriesName +
                      '<span style="color:' +
                      item.color +
                      ';display:inline-block;margin:2px 0px 1px 30px">' +
                      arr[index] +
                      '<span style=" font-weight: 700;">' +
                      "(" +
                      percentage[index] +
                      "%" +
                      ")" +
                      "</span>" +
                      "</span>" +
                      "<br/>";
                  } else if (index == 6) {
                    formatterStr +=
                      that.shiftName[2] +
                      "<br/>" +
                      item.marker +
                      item.seriesName +
                      '<span style="color:' +
                      item.color +
                      ';display:inline-block;margin:2px 0px 1px 30px">' +
                      arr[index] +
                      '<span style=" font-weight: 700;">' +
                      "(" +
                      percentage[index] +
                      "%" +
                      ")" +
                      "</span>" +
                      "</span>" +
                      "<br/>";
                  } else {
                    formatterStr +=
                      item.marker +
                      item.seriesName +
                      '<span style="color:' +
                      item.color +
                      ';display:inline-block;margin:2px 0px 1px 30px">' +
                      arr[index] +
                      '<span style=" font-weight: 700;">' +
                      "(" +
                      percentage[index] +
                      "%" +
                      ")" +
                      "</span>" +
                      "</span>" +
                      "<br/>";
                  }
                });
                return (
                  "<div  style='text-align:left'>" + formatterStr + "</div>"
                );
              }
            },
          },

          legend: {
            icon: "circle",
            textStyle: {
              fontsize: 10,
              padding: [0, 0, 0, -10],
            },
            right: 0,
            itemHeight: 10, //修改图形大小
            itemGap: 15, //修改间距
          },
          grid: {
            left: "0%",
            right: "5%",
            bottom: "5%",
            containLabel: true,
          },
          dataZoom: [
            {
              type: "inside",
              xAxisIndex: [0],
              start: 0,
              end: 100,
            },
          ],
          xAxis: [
            {
              type: "category",
              data: Ydata,
              axisLabel: { interval: 0, rotate: 40 },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: "{value} 时",
              },
              min: 0,
            },
          ],
          series: data,
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setPie(data, ref) {
        let legendDate = [];
        data = data.filter((item) => {
          if (item.value > 0) {
            legendDate.push({ name: item.name, icon: "circle" });
            return item;
          }
        });
        let option = {
          title: {
            text: "颜色时长总计",
            subtext: "Duration statistics",
            left: "left",
            textStyle: {
              fontSize: 12,
            },
          },
          legend: {
            orient: "vertical",
            left: "65%", //图例距离左的距离
            top: "center",
            data: legendDate,
            textStyle: {
              fontsize: 8,
              padding: [0, 0, 0, -10],
            },
            itemHeight: 10, //修改图形大小
            itemGap: 15, //修改间距
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              minAngle: 5,
              avoidLabelOverlap: true,
              radius: "95%",
              center: ["45%", "50%"],
              data: data,
              label: {
                normal: {
                  color: "#e8e8e8",
                  position: "inner", // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
                  // formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
                  // {a}指series.name  {b}指series.data的name
                  // {c}指series.data的value  {d}%指这一部分占总数的百分比
                  formatter: "{d|{d}%} \n {b|{b}:{c}小时}",
                  rich: {
                    d: {
                      color: "#fff",
                      fontSize: 8,
                      lineHeight: 10,
                      height: 10,
                    },
                    b: {
                      color: "#f3eae6",
                      fontSize: 8,
                      lineHeight: 10,
                      align: "left",
                    },
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      frequencybar(data, Xdata, ref) {
        let option = {
          title: {
            text: "颜色次数明细",
            subtext: "Frequency detail",
            textStyle: {
              fontSize: 12,
            },
          },
          tooltip: {
            position: function (point, params, dom, rect, size) {
              // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
              // 提示框位置
              var x = 0; // x坐标位置
              var y = 0; // y坐标位置

              // 当前鼠标位置
              var pointX = point[0];
              var pointY = point[1];

              // 外层div大小
              // var viewWidth = size.viewSize[0];
              // var viewHeight = size.viewSize[1];

              // 提示框大小
              var boxWidth = size.contentSize[0];
              var boxHeight = size.contentSize[1];

              // boxWidth > pointX 说明鼠标左边放不下提示框
              if (boxWidth > pointX) {
                x = 5;
              } else {
                // 左边放的下
                x = pointX - boxWidth;
              }

              // boxHeight > pointY 说明鼠标上边放不下提示框
              if (boxHeight > pointY) {
                y = 5;
              } else {
                // 上边放得下
                y = pointY - boxHeight;
              }

              return [x, y];
            },
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
            formatter: function (params) {
              let str = "";
              if (params.length > 4) {
                str =
                  params[0].axisValue + "<br/>" + that.shiftName[0] + "<br/>";
              } else {
                if (that.radio == 3) {
                  let index = params[0].dataIndex;
                  str =
                    params[0].axisValue +
                    "<br/>" +
                    '<span style="color:#409eff">' +
                    "开始日期:" +
                    "  " +
                    that.weekDate[index]["start"] +
                    "<br/>" +
                    "结束日期:" +
                    " " +
                    that.weekDate[index]["end"] +
                    "<br/>" +
                    "</span>";
                } else {
                  str = params[0].axisValue + "<br/>";
                }
              }
              if (params.length % 3 == 0) {
                params.forEach((item, index) => {
                  if (item.data) {
                    if (index == 3) {
                      str +=
                        that.shiftName[1] +
                        "<br/>" +
                        item.marker +
                        item.seriesName +
                        '<span style="display:inline-block;margin:2px 0px 1px 20px">' +
                        item.data +
                        "次" +
                        "</span>" +
                        "<br/>";
                    } else if (index == 6) {
                      str +=
                        that.shiftName[2] +
                        "<br/>" +
                        item.marker +
                        item.seriesName +
                        '<span style="display:inline-block;margin:2px 0px 1px 20px">' +
                        item.data +
                        "次" +
                        "</span>" +
                        "<br/>";
                    } else {
                      str +=
                        item.marker +
                        item.seriesName +
                        '<span style="display:inline-block;margin:2px 0px 1px 20px">' +
                        item.data +
                        "次" +
                        "</span>" +
                        "<br/>";
                    }
                  }
                });
                return str;
              }
            },
          },
          legend: {
            icon: "circle",
            textStyle: {
              fontsize: 10,
              padding: [0, 0, 0, -10],
            },
            right: 0,
            itemHeight: 10, //修改图形大小
            itemGap: 15, //修改间距
          },
          grid: {
            left: "0%",
            right: "5%",
            bottom: "5%",
            containLabel: true,
          },
          dataZoom: [
            {
              type: "inside",
              xAxisIndex: [0],
              start: 0,
              end: 100,
            },
          ],
          xAxis: [
            {
              type: "category",
              data: Xdata,
              axisLabel: {
                //文字倾斜
                interval: 0,
                rotate: 40,
              },
            },
          ],
          yAxis: [
            {
              type: "value",

              alignTicks: true,
              axisLabel: {
                formatter: "{value} 次",
              },
            },
          ],
          series: data,
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      frequencypie(data, ref) {
        let legendDate = [];
        data = data.filter((item) => {
          if (item.value > 0) {
            legendDate.push({ name: item.name, icon: "circle" });
            return item;
          }
        });
        let option = {
          title: {
            text: "颜色次数总计",
            subtext: "Frequency statistics",
            left: "left",
            textStyle: {
              fontSize: 12,
            },
          },
          legend: {
            orient: "vertical",
            left: "65%", //图例距离左的距离
            top: "center",
            data: legendDate,
            textStyle: {
              fontsize: 8,
              padding: [0, 0, 0, -10],
            },
            itemHeight: 10, //修改图形大小
            itemGap: 15, //修改间距
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: "95%",
              center: ["45%", "50%"],
              data: data,
              label: {
                normal: {
                  color: "#e8e8e8",
                  position: "inner", // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
                  // formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
                  // {a}指series.name  {b}指series.data的name
                  // {c}指series.data的value  {d}%指这一部分占总数的百分比
                  formatter: "{d|{d}%} \n {b|{b}:{c}次}",
                  rich: {
                    d: {
                      color: "#fff",
                      fontSize: 8,
                      lineHeight: 10,
                      height: 10,
                    },
                    b: {
                      color: "#f3eae6",
                      fontSize: 8,
                      lineHeight: 10,
                      align: "left",
                    },
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
    };
  },
  methods: {
    getUseRate() {
      let from = {
        startDate: that.dateValue.startDate,
        endDate: that.dateValue.endDate,
        projectId: that.$route.query.projectId,
        type: this.radio,
      };
      that.$api.triColorLamp.getUseRate(from).then((res) => {
        if (res.code == 200) {
          if (this.radio == 2) {
            that.ShiftData(res);
            return;
          }
          that.dateVisible = false;
          let red = [];
          let yellow = [];
          let green = [];
          let Xdata = [];
          let piered = 0;
          let pieyellow = 0;
          let piegreen = 0;
          res.data.forEach((item) => {
            if (that.radio == 3) {
              let obj = {
                start: item.startTime,
                end: item.endTime,
              };
              that.weekDate.push(obj);
            }
            piered += item["1"];
            pieyellow += item["2"];
            piegreen += item["3"];
            let newred = (item["1"] / 3600).toFixed(2) * 1;
            let newyellow = (item["2"] / 3600).toFixed(2) * 1;
            let newgreen = (item["3"] / 3600).toFixed(2) * 1;
            red.push(newred);
            yellow.push(newyellow);
            green.push(newgreen);
            if (that.radio == 1 || that.radio == 2) {
              Xdata.push(item["date"].substring(5));
            } else {
              Xdata.push(item["date"]);
            }
          });

          let data = [
            {
              name: "绿灯",
              type: "bar",
              stack: "Ad",
              barMaxWidth: 40,
              emphasis: {
                focus: "none",
                disabled: true,
              },
              itemStyle: {
                color: "rgb(96,185,51)",
              },
              data: green,
            },
            {
              name: "黄灯",
              type: "bar",
              stack: "Ad",
              barMaxWidth: 40,
              emphasis: {
                focus: "none",
                disabled: true,
              },
              itemStyle: {
                color: "rgb(255,185,51)",
              },
              data: yellow,
            },
            {
              name: "红灯",
              type: "bar",
              stack: "Ad",
              barMaxWidth: 40,
              emphasis: {
                focus: "none",
                disabled: true,
              },
              itemStyle: {
                color: "red",
              },
              data: red,
            },
          ];
          let pieData = [
            {
              value: (piegreen / 3600).toFixed(2),
              name: "绿灯",
              itemStyle: { color: "#60b933" },
            },
            {
              value: (pieyellow / 3600).toFixed(2),
              name: "黄灯",
              itemStyle: { color: "#ffb933" },
            },
            {
              value: (piered / 3600).toFixed(2),
              name: "红灯",
              itemStyle: { color: "#ff0000" },
            },
          ];
          that.setPie(pieData, "pieBox");
          that.setBar(data, Xdata, "barBox");
        }
      });
      that.$api.triColorLamp.getStateCount(from).then((res) => {
        if (res.code == 200) {
          if (this.radio == 2) {
            that.shiftFrequency(res);
          } else {
            that.getFrequency(res);
          }
        }
      });
    },
    //班次数据
    ShiftData(res) {
      if (res.data.length < 1) {
        this.$message.warning("暂无班次信息");
        return;
      }
      let red = [];
      let yellow = [];
      let green = [];

      let tgreen = [];
      let tred = [];
      let tyellow = [];

      let Tred = [];
      let Tyellow = [];
      let Tgreen = [];

      let piered = 0;
      let pieyellow = 0;
      let piegreen = 0;
      let Xdata = [];
      res.data.forEach((it, index) => {
        if (index == 0) {
          it.shifts.forEach((ite) => {
            that.shiftName.push(ite.shiftName);
          });
        }
        if (that.radio == 1 || that.radio == 2) {
          Xdata.push(it.date.substring(5));
        } else {
          Xdata.push(it.date);
        }
        it.shifts.forEach((item, index) => {
          piered += item["1"];
          pieyellow += item["2"];
          piegreen += item["3"];
          if (index == 0) {
            let newred = (item["1"] / 3600).toFixed(2) * 1;
            let newyellow = (item["2"] / 3600).toFixed(2) * 1;
            let newgreen = (item["3"] / 3600).toFixed(2) * 1;
            red.push(newred);
            yellow.push(newyellow);
            green.push(newgreen);
          } else if (index == 1) {
            let newred = (item["1"] / 3600).toFixed(2) * 1;
            let newyellow = (item["2"] / 3600).toFixed(2) * 1;
            let newgreen = (item["3"] / 3600).toFixed(2) * 1;
            tred.push(newred);
            tyellow.push(newyellow);
            tgreen.push(newgreen);
          } else if (index == 2) {
            let newred = (item["1"] / 3600).toFixed(2) * 1;
            let newyellow = (item["2"] / 3600).toFixed(2) * 1;
            let newgreen = (item["3"] / 3600).toFixed(2) * 1;
            Tred.push(newred);
            Tyellow.push(newyellow);
            Tgreen.push(newgreen);
          }
        });
      });
      let data1 = [
        {
          name: "绿灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(96,185,51)",
          },
          data: green,
        },
        {
          name: "黄灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(255,185,51)",
          },
          data: yellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: red,
        },
      ];
      let data2 = [
        {
          name: "绿灯",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(96,185,51)",
          },
          data: tgreen,
        },
        {
          name: "黄灯",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(255,185,51)",
          },
          data: tyellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: tred,
        },
      ];
      let data3 = [
        {
          name: "绿灯",
          type: "bar",
          stack: "AE",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(96,185,51)",
          },
          data: Tgreen,
        },
        {
          name: "黄灯",
          type: "bar",
          stack: "AE",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(255,185,51)",
          },
          data: Tyellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "AE",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: Tred,
        },
      ];
      let data = [];
      let pieData = [
        {
          value: (piegreen / 3600).toFixed(2),
          name: "绿灯",
          itemStyle: { color: "#60b933" },
        },
        {
          value: (pieyellow / 3600).toFixed(2),
          name: "黄灯",
          itemStyle: { color: "#ffb933" },
        },
        {
          value: (piered / 3600).toFixed(2),
          name: "红灯",
          itemStyle: { color: "#ff0000" },
        },
      ];
      if (res.data[0].shifts.length == 2) {
        data = [...data1, ...data2];
      } else {
        data = [...data1, ...data2, ...data3];
      }
      that.setPie(pieData, "pieBox");
      that.setBar(data, Xdata, "barBox");
      that.dateVisible = false;
    },
    //次数
    getFrequency(res) {
      let red = [];
      let yellow = [];
      let green = [];
      let Xdata = [];
      res.data.forEach((item) => {
        let newred = item["1"] || 0;
        let newyellow = item["2"] || 0;
        let newgreen = item["3"] || 0;
        red.push(newred);
        yellow.push(newyellow);
        green.push(newgreen);
        if (that.radio == 1 || that.radio == 2) {
          Xdata.push(item["date"].substring(5));
        } else {
          Xdata.push(item["date"]);
        }
      });
      let data = [
        {
          name: "绿灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(96,185,51)",
          },
          data: green,
        },
        {
          name: "黄灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(255,185,51)",
          },
          data: yellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: red,
        },
      ];
      let pieData = [
        {
          value: green.reduce((pre, curr) => {
            return (pre += curr);
          }, 0),
          name: "绿灯",
          itemStyle: { color: "#60b933" },
        },
        {
          value: yellow.reduce((pre, curr) => {
            return (pre += curr);
          }, 0),
          name: "黄灯",
          itemStyle: { color: "#ffb933" },
        },
        {
          value: red.reduce((pre, curr) => {
            return (pre += curr);
          }, 0),
          name: "红灯",
          itemStyle: { color: "#ff0000" },
        },
      ];
      this.$nextTick(() => {
        that.frequencybar(data, Xdata, "frequencybar");
        that.frequencypie(pieData, "frequencypie");
      });
    },
    //班次查询的次数
    shiftFrequency(res) {
      if (res.data.length < 1) {
        this.$message.warning("暂无班次信息");
        return;
      }
      let red = [];
      let yellow = [];
      let green = [];

      let tgreen = [];
      let tred = [];
      let tyellow = [];

      let Tred = [];
      let Tyellow = [];
      let Tgreen = [];

      let piered = 0;
      let pieyellow = 0;
      let piegreen = 0;

      let Xdata = [];
      res.data.forEach((it) => {
        if (that.radio == 1 || that.radio == 2) {
          Xdata.push(it.date.substring(5));
        } else {
          Xdata.push(it.date);
        }
        it.shifts.forEach((item, index) => {
          piered += item["1"];
          pieyellow += item["2"];
          piegreen += item["3"];
          if (index == 0) {
            let newred = item["1"] || 0;
            let newyellow = item["2"] || 0;
            let newgreen = item["3"] || 0;
            red.push(newred);
            yellow.push(newyellow);
            green.push(newgreen);
          } else if (index == 1) {
            let newred = item["1"] || 0;
            let newyellow = item["2"] || 0;
            let newgreen = item["3"] || 0;
            tred.push(newred);
            tyellow.push(newyellow);
            tgreen.push(newgreen);
          } else if (index == 2) {
            let newred = item["1"] || 0;
            let newyellow = item["2"] || 0;
            let newgreen = item["3"] || 0;
            Tred.push(newred);
            Tyellow.push(newyellow);
            Tgreen.push(newgreen);
          }
        });
      });
      let data1 = [
        {
          name: "绿灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(96,185,51)",
          },
          data: green,
        },
        {
          name: "黄灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(255,185,51)",
          },
          data: yellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "Ad",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: red,
        },
      ];
      let data2 = [
        {
          name: "绿灯",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(96,185,51)",
          },
          data: tgreen,
        },
        {
          name: "黄灯",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(255,185,51)",
          },
          data: tyellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "AC",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: tred,
        },
      ];
      let data3 = [
        {
          name: "绿灯",
          type: "bar",
          stack: "AE",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(96,185,51)",
          },
          data: Tgreen,
        },
        {
          name: "黄灯",
          type: "bar",
          stack: "AE",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "rgb(255,185,51)",
          },
          data: Tyellow,
        },
        {
          name: "红灯",
          type: "bar",
          stack: "AE",
          barMaxWidth: 40,
          emphasis: {
            focus: "none",
            disabled: true,
          },
          itemStyle: {
            color: "red",
          },
          data: Tred,
        },
      ];
      let data = [];
      let pieData = [
        { value: piegreen, name: "绿灯", itemStyle: { color: "#60b933" } },
        { value: pieyellow, name: "黄灯", itemStyle: { color: "#ffb933" } },
        { value: piered, name: "红灯", itemStyle: { color: "#ff0000" } },
      ];
      if (res.data[0].shifts.length == 2) {
        data = [...data1, ...data2];
      } else {
        data = [...data1, ...data2, ...data3];
      }
      that.frequencybar(data, Xdata, "frequencybar");
      that.frequencypie(pieData, "frequencypie");
    },
  },
  mounted() {
    that = this;
    let date = new Date();
    let full = date.getFullYear();
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let endDate = full + "-" + month + "-" + Day;
    if (Day > 6) {
      Day -= 6;
      Day = Day < 10 ? "0" + Day : Day;
    } else {
      if (month - 1 < 1) {
        full -= 1;
        month = 12;
      } else {
        month -= 1;
        if (month < 10) {
          month = "0" + month;
        }
      }
      Day = new Date(full, month, 0).getDate() + Day * 1 - 6;
    }

    let startDate = full + "-" + month + "-" + Day;

    that.dateValue = { startDate, endDate };
    that.getUseRate();
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.header {
  height: 50px;
  padding: 0px 20px;
  border-radius: 15px 15px 0px 0px;
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.oeebody {
  height: calc(100% - 50px);
  width: 100%;
  box-sizing: border-box;
  /* display: flex;
  justify-content: space-between; */
  padding: 10px;
}
.echartsBox {
  margin-top: 5px;
  width: 100%;
  height: 49%;
  border: 1px solid #f7f8fa;
  border-radius: 10px;
  box-shadow: 0 0 5px #333;
}
#barBox {
  width: 100%;
  height: 70%;
}
#pieBox {
  width: 100%;
  height: 30%;
}
#frequencybar {
  width: 100%;
  height: 70%;
}
#frequencypie {
  width: 100%;
  height: 30%;
}
::v-deep .el-dialog__body {
  padding: 5px 20px;
}
</style>
